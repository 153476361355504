
import Vue, { PropType } from "vue";
import aSelect from "./a-select.vue";

export default Vue.extend({
  name: "ASelectStatus",

  components: { aSelect },

  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    value: {
      type: [String, Number, Array, undefined] as PropType<
        string | number | Array<string | number>
      >,
      default: "",
    },
  },

  data() {
    return {
      items: [
        { text: "Ativo", value: "0" },
        { text: "Inativo", value: "1" },
        { text: "Ambos", value: "" },
      ],
    };
  },
});
