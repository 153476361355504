
import ABtn from "@/components/elements/a-btn.vue";
import ACard from "@/components/elements/a-card.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IAccountPlan from "@/lib/interfaces/account-plan";
import Vue, { PropType } from "vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "AccountPlansList",

  components: {
    vAlertMessage,
    ABtn,
    ADataTable,
    ACard,
    ATextField,
    ASelectStatus,
  },

  props: {
    accountPlan: {
      type: Object as PropType<IAccountPlan>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      search: "",
      desativado: "0",

      accountPlans: new Array<IAccountPlan>(),

      headers: [
        { text: "Nivel", value: "nivel" },
        { text: "Descrição", value: "descricao" },
        { text: "Hierarquia", value: "cHierarquia" },
        { text: "Tipo", value: "cTipo" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAccountPlans().then();
    },
  },

  computed: {
    cAccountPlans(): Array<Record<string, unknown>> {
      return this.accountPlans.map((accountPlan) => {
        const parent = this.accountPlans.find(
          (item) => item.id === accountPlan.hierarquia
        );

        const cHierarquia = parent
          ? `${parent.nivel} - ${parent.descricao}`
          : "N/A";

        const cTipo = { "1": "Crédito", "2": "Débito", "3": "Ambos" }[
          accountPlan.tipo
        ];

        return {
          ...accountPlan,

          cHierarquia,
          cTipo,
          cDesativado: accountPlan.desativado === "0" ? "Ativo" : "Inativo",
        };
      });
    },
  },

  mounted() {
    this.getAccountPlans().then();
  },

  methods: {
    async openAccountPlan(id: string) {
      const accountPlan = this.accountPlans.find(
        (accountPlan) => accountPlan.id === id
      );

      this.$emit("update:account-plan", { ...accountPlan });
    },

    async getAccountPlans() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read", {
          sortBy: "nivel",
          desativado: this.desativado,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlans = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
