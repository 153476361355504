
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import ASelect from "./a-select.vue";

export default Vue.extend({
  name: "ADataTable",

  components: { ASelect },

  data() {
    return {
      optionsSync: {} as DataOptions,

      itemsPerPageOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "Todos", value: -1 },
      ],
    };
  },

  props: {
    dense: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    disableFiltering: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disablePagination: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disableSort: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    headers: {
      type: Array as PropType<Array<Record<string, unknown>>>,
      required: true,
    },

    items: {
      type: Array as PropType<Array<Record<string, unknown>>>,
      required: true,
    },

    itemKey: {
      type: String as PropType<string>,
      default: "id",
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    options: {
      type: Object as PropType<DataOptions>,
      default: () => ({}),
    },

    search: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    serverItemsLength: {
      type: Number as PropType<number>,
      default: -1,
    },
  },

  computed: {
    cOrderByOptions(): Array<Record<string, unknown>> {
      return this.headers.filter((header) => header.sortable !== false);
    },

    cShowSortBy(): boolean {
      return (
        this.items.length > 0 &&
        !this.disableSort &&
        this.cOrderByOptions.length > 0
      );
    },

    cSearch(): string | undefined {
      if (this.serverItemsLength > -1 || this.disableFiltering)
        return undefined;

      return this.search;
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.optionsSync = this.options as DataOptions;
      },
    },

    optionsSync: {
      deep: true,
      handler() {
        this.$emit("update:options", this.optionsSync);
      },
    },

    search() {
      this.optionsSync.page = 1;
    },
  },

  methods: {
    evOnItemsPerPageInput(itemsPerPage: number) {
      this.optionsSync.itemsPerPage = itemsPerPage;
      this.optionsSync.page = 1;
    },

    evOnSortByInput(value: string | Array<string>) {
      if (!value) this.optionsSync.sortBy = [];

      if (Array.isArray(value)) {
        this.optionsSync.sortBy = value;
      } else {
        this.optionsSync.sortBy = [value];
      }
    },
  },
});
