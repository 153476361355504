
import Vue from "vue";
import AccountPlansList from "@/components/subviews/account-plans/account-plans-list.vue";
import AccountPlansMaintenance from "@/components/subviews/account-plans/account-plans-maintenance.vue";
import IAccountPlan from "@/lib/interfaces/account-plan";

export default Vue.extend({
  name: "AccountPlans",

  components: { AccountPlansList, AccountPlansMaintenance },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      accountPlan: {} as IAccountPlan,
    };
  },
});
